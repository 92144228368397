<template>
    <a-layout style="height: 100vh;">
        <a-layout-header class="header">
            <div class="logo">税政宝</div>
            <a-dropdown class="info" :trigger="['click']">
                <a class="ant-dropdown-link">
                    <UserOutlined/>
                    {{ type === 'admin' ? name : companyName }}
<!--                    <DownOutlined />-->
                </a>
                <template #overlay>
                    <a-menu>
                        <a-menu-item>
                            <a href="javascript:;" @click="logout">
                                <LogoutOutlined />
                                退出
                            </a>
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </a-layout-header>
        <a-layout>
            <a-layout-sider width="200" class="layout-sider">
                <a-menu
                    mode="inline"
                    v-model:selectedKeys="selectedKeys"
                    v-model:openKeys="openKeys"
                    :style="{ height: '100%', borderRight: 0 }"
                    @click="addTab">
                    <template v-for="subMenu in menus">
                        <a-menu-item v-if="subMenu.list.length === 0" :key="subMenu.route">{{ subMenu.name }}
                        </a-menu-item>
                        <a-sub-menu v-else :key="subMenu.route">
                            <template #title><span>{{ subMenu.name }}</span></template>
                            <a-menu-item v-for="menu in subMenu.list" :key="menu.route">{{ menu.name }}</a-menu-item>
                        </a-sub-menu>
                    </template>
                </a-menu>
            </a-layout-sider>
            <a-layout>
                <a-layout-content class="layout-content">
                    <!--<a-tabs v-model:activeKey="activeKey" type="editable-card" @edit="onEdit" :hideAdd="true" @tabClick="tabClick">
                        <a-tab-pane v-for="tab in tabs" :key="tab.route" :tab="tab.name" :closable="tab.closable"></a-tab-pane>
                    </a-tabs>
                    <transition>
                        <keep-alive :max="10" :include="cacheTabs">
                            <component :is="activeKey"></component>
                        </keep-alive>
                    </transition>-->
                    <component :is="activeKey"></component>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-layout>
</template>
<script lang="ts">
import {UserOutlined, LogoutOutlined, DownOutlined} from '@ant-design/icons-vue';
import {defineComponent, ref, computed} from 'vue';
import Menu from "@/request/Menu";
import SessionStorageKeys from "@/common/SessionStorageKeys";
import router from '@/router';

export default defineComponent({
    components: {
        UserOutlined,
        LogoutOutlined,
        DownOutlined
    },
    setup() {
        const menus = ref<any[]>([]);

        Menu.getMenu().then((body: any) => menus.value = body.data)

        const tabs = ref([
            {name: '员工管理', route: 'Employee', closable: false}
        ])

        const activeKey = ref(tabs.value[0].route);

        const tabClick = (key: string) => {
            let tab = tabs.value.filter((j: any) => j.route === key)[0];
        }

        const addTab = (item: any) => {
            const menu: Menu = findMenuInMenus(item.key);
            activeKey.value = item.key;
            let b: boolean = tabs.value.some((j: any) => j.route === item.key);
            if (!b) {
                tabs.value.push({
                    name: menu.name,
                    route: menu.route,
                    closable: true
                })
            }
        }

        const remove = (targetKey: string) => {
            let lastIndex = 0;
            tabs.value.forEach((tab, i) => {
                if (tab.route === targetKey) {
                    lastIndex = i - 1;
                }
            });
            tabs.value = tabs.value.filter(tab => tab.route !== targetKey);
            if (tabs.value.length && activeKey.value === targetKey) {
                if (lastIndex >= 0) {
                    activeKey.value = tabs.value[lastIndex].route;
                } else {
                    activeKey.value = tabs.value[0].route;
                }
            }
        };

        const onEdit = (targetKey: string | MouseEvent, action: string) => {
            if (action === 'remove') {
                remove(targetKey as string);
            }
        };

        const findMenuInMenus = (key: string) => {
            let menu: Menu = menus.value.filter((j: Menu) => j.route === key)[0];
            if (menu === undefined) {
                menu = menus.value.flatMap((j: Menu) => j.list).filter((k: Menu) => k.route === key)[0];
            }
            return menu;
        }

        const cacheTabs = computed(() => {
            return tabs.value.map((j: any) => j.route);
        });

        const logout = () => {
            sessionStorage.removeItem(SessionStorageKeys.type)
            sessionStorage.removeItem(SessionStorageKeys.companyId)
            sessionStorage.removeItem(SessionStorageKeys.companyName)
            sessionStorage.removeItem(SessionStorageKeys.name)
            sessionStorage.removeItem(SessionStorageKeys.token)
            router.replace("/")
        }

        return {
            menus,
            tabs,
            logout,
            activeKey,
            onEdit,
            tabClick,
            addTab,
            name: sessionStorage.getItem(SessionStorageKeys.name),
            companyName: sessionStorage.getItem(SessionStorageKeys.companyName),
            type: sessionStorage.getItem('type'),
            cacheTabs,
            selectedKeys: ref<string[]>(['2']),
            collapsed: ref<boolean>(false),
            openKeys: ref<string[]>(['1']),
        };
    },
});
</script>
<style lang="less" scoped>

.layout-sider {
    background: #fff;
    overflow-y: scroll;
}

.layout-content {
    background-color: #ffffff;
    margin: 0;
    overflow: scroll;
    padding: 5px;
}

.logo {
    float: left;
    text-align: center;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.7);
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.avatar {
    float: right;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.info {
    float: right;
    line-height: 64px;
    height: 64px;
}

</style>
