import {Request} from "@/Request";

class Menu extends Request {
    public path: string = '/menu';
    
    getMenu = () => {
        return this.get(`${this.path}/list`);
    }
    
}

export default new Menu();