
import {UserOutlined, LogoutOutlined, DownOutlined} from '@ant-design/icons-vue';
import {defineComponent, ref, computed} from 'vue';
import Menu from "@/request/Menu";
import SessionStorageKeys from "@/common/SessionStorageKeys";
import router from '@/router';

export default defineComponent({
    components: {
        UserOutlined,
        LogoutOutlined,
        DownOutlined
    },
    setup() {
        const menus = ref<any[]>([]);

        Menu.getMenu().then((body: any) => menus.value = body.data)

        const tabs = ref([
            {name: '员工管理', route: 'Employee', closable: false}
        ])

        const activeKey = ref(tabs.value[0].route);

        const tabClick = (key: string) => {
            let tab = tabs.value.filter((j: any) => j.route === key)[0];
        }

        const addTab = (item: any) => {
            const menu: Menu = findMenuInMenus(item.key);
            activeKey.value = item.key;
            let b: boolean = tabs.value.some((j: any) => j.route === item.key);
            if (!b) {
                tabs.value.push({
                    name: menu.name,
                    route: menu.route,
                    closable: true
                })
            }
        }

        const remove = (targetKey: string) => {
            let lastIndex = 0;
            tabs.value.forEach((tab, i) => {
                if (tab.route === targetKey) {
                    lastIndex = i - 1;
                }
            });
            tabs.value = tabs.value.filter(tab => tab.route !== targetKey);
            if (tabs.value.length && activeKey.value === targetKey) {
                if (lastIndex >= 0) {
                    activeKey.value = tabs.value[lastIndex].route;
                } else {
                    activeKey.value = tabs.value[0].route;
                }
            }
        };

        const onEdit = (targetKey: string | MouseEvent, action: string) => {
            if (action === 'remove') {
                remove(targetKey as string);
            }
        };

        const findMenuInMenus = (key: string) => {
            let menu: Menu = menus.value.filter((j: Menu) => j.route === key)[0];
            if (menu === undefined) {
                menu = menus.value.flatMap((j: Menu) => j.list).filter((k: Menu) => k.route === key)[0];
            }
            return menu;
        }

        const cacheTabs = computed(() => {
            return tabs.value.map((j: any) => j.route);
        });

        const logout = () => {
            sessionStorage.removeItem(SessionStorageKeys.type)
            sessionStorage.removeItem(SessionStorageKeys.companyId)
            sessionStorage.removeItem(SessionStorageKeys.companyName)
            sessionStorage.removeItem(SessionStorageKeys.name)
            sessionStorage.removeItem(SessionStorageKeys.token)
            router.replace("/")
        }

        return {
            menus,
            tabs,
            logout,
            activeKey,
            onEdit,
            tabClick,
            addTab,
            name: sessionStorage.getItem(SessionStorageKeys.name),
            companyName: sessionStorage.getItem(SessionStorageKeys.companyName),
            type: sessionStorage.getItem('type'),
            cacheTabs,
            selectedKeys: ref<string[]>(['2']),
            collapsed: ref<boolean>(false),
            openKeys: ref<string[]>(['1']),
        };
    },
});
